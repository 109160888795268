<template>
  <piepkaart
    :minLoadDataZoom="14"
    :minRefetchDistance="100"
    @popupopen="onPopup"
  >
    <template v-slot="{ bounds, minLoadDataZoom }">
      <piepkaart-wfs-layer
        :bounds="bounds"
        :minLoadDataZoom="minLoadDataZoom"
        wfs-url="https://service.pdok.nl/rvo/brpgewaspercelen/wfs/v1_0"
        type-name="brpgewaspercelen:BrpGewas"
        output-format="application/json; subtype=geojson"
        srs-name="EPSG:4326"
        :reverse-bbox="true"
        :use-proxy="false"
        version="2.0.0"
        :onEachFeature="onEachFeature"
        :styleOptions="styleOptions"
      ></piepkaart-wfs-layer>
    </template>
  </piepkaart>
</template>
<script>
import Piepkaart from "@/components/Piepkaart.vue";
import PiepkaartWfsLayer from "@/components/PiepkaartWfsLayer.vue";
export default {
  name: "Gewassen",
  components: {
    Piepkaart,
    PiepkaartWfsLayer,
  },
  methods: {
    styleOptions() {
      return {
        weight: 0.33,
        color: "#000",
        opacity: 1,
        fillColor: "#ccc",
        fillOpacity: 0.75,
      };
    },
    onEachFeature(feature, layer) {
      const aardappelen = [2025, 2014, 2015, 2016, 2017];
      const mais = [317, 2032, 316, 259, 814, 1935];
      const graszaad = [383];
      const grasBlijvend = [265];
      const grasTijdelijk = [266];
      const grasNatuurlijk = [331, 332];
      const natuurterrein = [335];
      const bieten = [256, 257];
      const tarwe = [233, 234];
      const uien = [1932, 1933, 262, 263];
      if (feature.properties) {
        if (uien.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#F59E0B",
          });
        }

        if (aardappelen.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#975a16",
          });
        }

        if (mais.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#f6e05e",
          });
        }

        if (graszaad.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#9ae6b4",
          });
        }

        if (grasTijdelijk.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#68d391",
          });
        }

        if (grasBlijvend.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#2f855a",
          });
        }

        if (natuurterrein.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#38a169",
          });
        }

        if (grasNatuurlijk.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#276749",
          });
        }

        if (bieten.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#F472B6",
          });
        }

        if (tarwe.includes(feature.properties.gewascode)) {
          layer.setStyle({
            fillColor: "#fbd38d",
          });
        }
        layer.bindPopup(
          `
            <div style="font-weight: 600">${feature.properties.category}</div>
            <div>${feature.properties.gewas}</div>
            `,
          {
            closeButton: false,
            autoPan: false,
          }
        );
      }
    },
    onPopup(popup) {
      const latlng = popup._popup._latlng;
      const location = `${parseFloat(latlng.lat.toFixed(6))},${parseFloat(
        latlng.lng.toFixed(6)
      )}`;
      const { gewas } = popup.feature.properties;

      window.gtag("event", "gewassen", {
        location: location,
        gewas: gewas,
      });
    },
  },
};
</script>